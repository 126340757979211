/* eslint-disable import/prefer-default-export */
import { addMonths, startOfYear } from 'date-fns';

export function startOfHalfYear(date) {
  // Get the start of the year for the given date
  const startOfYearDate = startOfYear(date);

  // Add 6 months to the start of the year to get the start of the second half-year
  const startOfSecondHalfYear = addMonths(startOfYearDate, 6);

  // If the given date is before the start of the second half-year, then it belongs to the first half-year
  if (date < startOfSecondHalfYear) {
    return startOfYearDate;
  }
  // Otherwise, it belongs to the second half-year
  return startOfSecondHalfYear;
}
