import { get } from 'lodash';

import { createSelector } from '@reduxjs/toolkit';
import { actionTypes, getCompleteFaqItems } from 'actions/campaign';
import { createLoadingSelector } from 'reducers/ui';

/**
 * Get the tag set list in format for the dropdowns options props
 *
 * @param {*} state Redux state
 */
const generateTagSetsOptions = (tagSets) =>
  tagSets.map((tagSet) => ({
    key: tagSet.id,
    value: tagSet.id,
    text: tagSet.name,
  }));

/**
 * Get the default value, the display value and if the value is the one currently saved in db for a specific field in a specific state
 *
 * @param {*} state Redux state
 * @param {*} campaignId Id of the campaign being customized
 * @param {*} page Page of the campaign where the field is at
 * @param {*} inputKey Key of the field to get
 * @return {*} Default value, displayed value, is the value displayed default
 */
export const getWordingValue = (state, campaignId, page, inputKey) => {
  const defaultWording =
    state.campaign.customization[campaignId].currentCampaignConfiguration
      .configuration_ui.wording[page][inputKey];
  const customWording = get(
    state.campaign.customization[campaignId].diff.configuration_ui.wording,
    [page, inputKey],
    null
  );
  let isDefault = customWording === null;
  if (customWording === defaultWording) {
    isDefault = true;
  }
  return {
    defaultWording,
    value: isDefault ? defaultWording : customWording,
    isDefault,
  };
};

export const previewActivatedSelector = (state) =>
  state.campaign.previewActivated;
export const restorationActivatedSelector = (state) =>
  state.campaign.restorationActivated;
export const hasUnsavedChangesSelector = (state) =>
  state.campaign.hasUnsavedChanges;
export const campaignCustomizationSelectorFactory = (campaignId) => (state) =>
  state.campaign.customization[campaignId];
export const campaignSatisfactionTagSetIdSelectorFactory =
  (campaignId) => (state) =>
    state.campaign.customization[campaignId]?.currentCampaignConfiguration
      ?.satisfaction_tag_set?.id;

export const campaignConfigurationIsLoadingSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (state) => state.loading,
    (campaignCustomization, loading) =>
      createLoadingSelector([actionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST])(
        loading
      ) || !campaignCustomization
  );

export const campaignFaqItemsDeletionSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (campaignCustomization) => campaignCustomization.faq_items_deletion
  );

export const faqItemsCurrentSizeSelectorFactory = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (campaignCustomization) => campaignCustomization.faq_items_current_size
  );
export const isFaqItemsEmptySelectorFactory = (campaignId) =>
  createSelector(
    campaignFaqItemsDeletionSelectorFactory(campaignId),
    faqItemsCurrentSizeSelectorFactory(campaignId),
    (faqItemsDeletion, faqItemsCurrentSize) =>
      faqItemsDeletion.length === faqItemsCurrentSize.faq_items_current_size
  );
export const faqItemsSelectorFactory = (campaignId) => (state) =>
  getCompleteFaqItems(state, campaignId);

export const wordingValueSelectorFactory =
  (campaignId, page, inputKey) => (state) =>
    getWordingValue(state, campaignId, page, inputKey);

export const onGenerateTagSetsOptionsSelector = (state) =>
  generateTagSetsOptions(state.campaign.tagSets);

export const campaignConfigurationSelectorFactory = (campaignId) => (state) =>
  state.campaign.customization[campaignId]?.currentCampaignConfiguration ||
  null;

const campaignConfigurationDiffSelectorFactory = (campaignId) => (state) =>
  state.campaign.customization[campaignId]?.diff || null;

export const tagSetDropdownSelectorFactory =
  (campaignId, tagSetId) => (state) =>
    state.campaign.tagSets
      .find(({ id }) => id === tagSetId)
      ?.items.map(({ id, name, color }) => ({
        value: id,
        key: id,
        text: name,
        color,
      })) || null;

export const formSelectorFactory = (campaignId, field, withDiff = false) =>
  createSelector(
    campaignConfigurationSelectorFactory(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) => {
      const form = [...(configuration?.[field] || [])];
      if (withDiff) {
        const formIds = form.map(({ id }) => id);
        diff?.[field]?.forEach((item) => {
          const formIdsIndex = formIds.findIndex((id) => id === item.id);
          if (formIdsIndex > -1) {
            form[formIdsIndex] = item;
          } else {
            form.push(item);
          }
        });
      }
      return form?.sort((a, b) => a.position - b.position);
    }
  );

export const randomizeSelectorFactory = (campaignId) =>
  createSelector(
    campaignConfigurationSelectorFactory(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) =>
      diff.randomize !== undefined ? diff.randomize : configuration.randomize
  );

export const expandedFormSelectorFactory = (campaignId) =>
  createSelector(
    campaignConfigurationSelectorFactory(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) =>
      diff.configuration_ui.expanded_form !== undefined
        ? diff.configuration_ui.expanded_form
        : configuration.configuration_ui.expanded_form
  );

export const highlightConceptsSelectorFactory = (campaignId) =>
  createSelector(
    campaignConfigurationSelectorFactory(campaignId),
    campaignConfigurationDiffSelectorFactory(campaignId),
    (configuration, diff) =>
      (diff.highlight_concepts || configuration.highlight_concepts).map(
        ({ id }) => id
      )
  );

export const formElementSelectorFactory = (
  campaignId,
  field,
  formId,
  loadTagSet = false
) =>
  createSelector(
    campaignConfigurationSelectorFactory(campaignId),
    (state) => state,
    (configuration, state) => {
      const formElement =
        (configuration &&
          configuration[field] &&
          configuration[field].find((item) => item.id === formId)) ||
        {};

      if (loadTagSet && formElement) {
        return {
          formElement,
          tagSet: tagSetDropdownSelectorFactory(
            campaignId,
            formElement.tag_set?.id
          )(state),
        };
      }
      return formElement;
    }
  );

export const campaignConfigurationIdentificationFormDeletionSelectorFactory = (
  campaignId,
  field,
  formId,
  loadTagSet = false
) =>
  createSelector(
    formSelectorFactory(campaignId, field, formId, loadTagSet),
    campaignCustomizationSelectorFactory(campaignId),
    (identificationForm, configuration) =>
      identificationForm?.map((formElement) => ({
        ...formElement,
        archived: configuration.identification_form_deletion.includes(
          formElement.id
        ),
      }))
  );

export const campaignTagSetsSelectorFactory = (campaignId) =>
  createSelector(
    campaignConfigurationSelectorFactory(campaignId),
    (configuration) => (configuration ? configuration.tag_sets : [])
  );
export const campaignTagSetsItemsSelectorFactory = (state) => {
  const tagSetMap = {};
  Object.entries(state.entities.tagSets).forEach(([tagSetId, tagSet]) => {
    tagSetMap[tagSetId] = {
      ...tagSet,
      items: tagSet.items.map((item) => ({
        key: item.id,
        value: item.id,
        label: item.name,
      })),
    };
  });
  return tagSetMap;
};

export const campaignsSelector = (state) => state.campaign.campaigns;
export const campaignSelector = (campaignId) => (state) =>
  state.campaign.customization[campaignId]?.currentCampaignConfiguration;
export const publicationDateSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaign) => campaign?.publication_date || null
  );

export const campaignNameSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (campaign) => campaign?.name || null
  );

export const campaignOntologySelectorFactory = (campaignId) => (state) => {
  const { ontology } = campaignConfigurationSelectorFactory(campaignId)(state);
  const selectedOntology = state.entities.ontologiesById[ontology.id];

  return {
    ...selectedOntology,
    concepts: (selectedOntology?.concepts || [])
      .filter((item) => item.is_non_attributed === false)
      .map((filteredItem) => ({
        key: filteredItem.id,
        value: filteredItem.id,
        // label: item.name,
        text: filteredItem.name,
      })),
  };
};
