import { i18n } from '@lingui/core';
import { format } from 'date-fns';

import { actionTypes, fnsLocales, loadLanguage } from 'actions/locale';

import produce from 'immer';
import { en, es, fr, it, nl } from 'make-plural/plurals';

import config from 'config';

i18n.loadLocaleData({
  en: { plurals: en },
  fr: { plurals: fr },
  it: { plurals: it },
  nl: { plurals: nl },
  es: { plurals: es },
  'fr-pole-emploi': { plurals: fr },
  'fr-product-service': { plurals: fr },
});

export const LANGUAGES = [
  { value: 'en', text: 'English', flag: 'us' },
  { value: 'fr', text: 'Français', flag: 'fr' },
  { value: 'it', text: 'Italiano', flag: 'it' },
  { value: 'nl', text: 'Nederlandse', flag: 'nl' },
  { value: 'es', text: 'Español', flag: 'es' },
];

export const getLanguageInfo = (language) => {
  const languageInfo = LANGUAGES.find(({ value }) => value === language);
  return languageInfo || { value: 'language', text: '-', flag: '' };
};

export const formatDate = (date, formatString) =>
  format(date, formatString, { locale: fnsLocales[window.__locale__] });

// Load default locale to make sure app displays from local storage rather than redux to make sure
// it is loaded before redux is
let defaultLocale =
  localStorage.getItem('language') ||
  navigator.language ||
  navigator.userLanguage;
if (config.FORCE_CUSTOM_LOCALE) {
  defaultLocale = config.FORCE_CUSTOM_LOCALE;
} else {
  defaultLocale = defaultLocale?.includes('fr') ? 'fr' : 'en';
}

const getLanguageCode = (languageString, languageCode) =>
  languageString?.startsWith(languageCode) && languageCode;

export const getInitialLanguage = (baseLanguage = navigator.language) => {
  const languageValue =
    (config.FORCE_CUSTOM_LOCALE === 'fr-pole-emploi' && 'fr') ||
    (config.FORCE_CUSTOM_LOCALE === 'fr-product-service' &&
      'fr-product-service') ||
    getLanguageCode(baseLanguage, 'fr') ||
    getLanguageCode(baseLanguage, 'en') ||
    getLanguageCode(baseLanguage, 'it') ||
    getLanguageCode(baseLanguage, 'es') ||
    getLanguageCode(baseLanguage, 'nl') ||
    'en';
  loadLanguage(languageValue);
  return languageValue;
};

const getInitialState = () => ({
  language: getInitialLanguage(defaultLocale),
  catalogs: {},
  loaded: [],
});

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SWITCH_LANGUAGE_SUCCESS:
      draft.language = action.language;
      localStorage.setItem('language', action.language);
      draft.loaded.push(action.language);
      break;
    default:
      break;
  }
  return draft;
}, getInitialState());

export const languageSelector = (state) =>
  LANGUAGES.find(({ value }) => value === state.locale.language);
