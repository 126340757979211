import ReactGA from 'react-ga';

import amplitude from 'amplitude-js';

import config from 'config';

// Initialize Google analytics
if (config.ga.property) {
  ReactGA.initialize(config.ga.property, {
    gaOptions: { cookieExpires: 31536000 },
  });
}

// Initialize Amplitude
if (config.amplitude.key) {
  amplitude.getInstance().init(config.amplitude.key, null, {
    // domain : (from amplitude docs) Set a custom domain for the Amplitude cookie.
    // To include subdomains, add a preceding period, eg: .amplitude.com.
    domain: '.bw-amplitude',
    batchEvents: true,
    eventUploadThreshold: 3,
    appVersion: process.env.npm_package_version,
  });
}

export const logEvent = ({ category, action, label, nonInteraction }) => {
  const logProps = { category };
  if (label) logProps.label = label;
  if (nonInteraction) logProps.nonInteraction = nonInteraction;

  if (config.ga.property) ReactGA.event({ action, ...logProps });
  if (config.amplitude.key) amplitude.getInstance().logEvent(action, logProps);
};

export /**
 * Set user id to analytics apps (used at login time).
 *
 * @param {*} userId the user id.
 */
const setAnalyticsUserGlobally = (userId, organizationId) => {
  ReactGA.set({ userId });
  if (config.amplitude.key) {
    amplitude.setUserId(userId);
    if (organizationId) {
      const identify = new amplitude.Identify().setOnce(
        'organization id',
        organizationId
      );
      amplitude.identify(identify);
    }
  }
};

export /**
 * Forget user id on analytics apps (used at logout time).
 *
 */
const forgetUserGlobally = () => {
  setAnalyticsUserGlobally(null);
  if (config.amplitude.key) amplitude.getInstance().regenerateDeviceId();
};
