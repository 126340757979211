import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { logEvent } from 'utils/analytics';

/**
 * Analytics HOC for elements with interaction
 *
 * @param {Component} WrappedComponent the component to wrap
 * @param {String} clickPropName the name of the interaction function prop to wrap (ex: onClick, onChange, ...)
 * @return {Component} a new component that send analytics event when interacted with.
 */
const withClickAnalytics = (WrappedComponent, clickPropName) => {
  function AnalyticsAwareComponent({
    gaCategory,
    gaAction,
    gaLabel,
    gaNonInteraction,
    ...props
  }) {
    const onClickAction = useCallback(
      (...params) => {
        if (gaCategory !== undefined || gaAction !== undefined) {
          const gaProps = {
            category: gaCategory,
            action: gaAction,
            label: gaLabel,
            nonInteraction: gaNonInteraction,
          };
          logEvent(gaProps);
        }
        if (props[clickPropName]) props[clickPropName](...params);
      },
      [props[clickPropName], gaCategory, gaAction, gaLabel]
    );
    return (
      <WrappedComponent {...props} {...{ [clickPropName]: onClickAction }} />
    );
  }
  AnalyticsAwareComponent.propTypes = {
    ...WrappedComponent.propTypes,
    gaCategory: PropTypes.string,
    gaAction: PropTypes.string,
    gaLabel: PropTypes.string,
    gaNonInteraction: PropTypes.bool,
  };
  AnalyticsAwareComponent.defaultProps = {
    ...WrappedComponent.defaultProps,
    gaCategory: undefined,
    gaAction: undefined,
    gaLabel: undefined,
    gaNonInteraction: false,
  };
  return AnalyticsAwareComponent;
};

export default withClickAnalytics;
