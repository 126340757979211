import { actionTypes } from 'actions/user';

import amplitude from 'amplitude-js';
import produce from 'immer';

const initialState = {
  isAuthenticated: false,
  loginRequestError: false,
  logAsError: false,
  user: {
    isAdmin: false,
    isCsm: false,
  },
  appConfig: {},
};

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT_FAILURE:
    case actionTypes.LOGOUT_SUCCESS:
      draft.isAuthenticated = false;
      draft.user = { isAdmin: false, isCsm: false };
      break;
    case actionTypes.ADMIN_LOG_AS_SUCCESS:
      draft.user.id = action.user.id;
      draft.user.isAdmin = action.user.admin;
      draft.appConfig = action.user.config;
      // Do not track events for log as admin users
      amplitude.getInstance().setOptOut(true);
      break;
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.WHOAMI_SUCCESS:
      draft.isAuthenticated = true;
      draft.user.id = action.user.id;
      draft.user.isAdmin = action.user.admin;
      draft.user.isCsm = action.user.is_csm;
      draft.appConfig = action.user.config;
      break;
    case actionTypes.LOGIN_FAILURE:
      draft.loginRequestError = true;
      break;
    case actionTypes.ADMIN_LOG_AS_FAILURE:
      draft.logAsError = action.error;
      break;
    default:
      break;
  }
  return draft;
}, initialState);
