import { unset } from 'lodash';

import { actionTypes } from 'actions/campaign';
import { actionTypes as entitiesActionTypes } from 'actions/entities';
import { actionTypes as userActionTypes } from 'actions/user';

import produce from 'immer';

const calculateCurrentSize = (currentList, diffList) => {
  let size = currentList.length;
  const currentIds = currentList.map((element) => element.id);
  diffList.forEach((element) => {
    if (!currentIds.includes(element.id)) {
      size += 1;
    }
  });
  return size;
};

const getCampaignInitialState = () => ({
  campaigns: null,
  tagSets: [],
  customization: {},
  previewActivated: false,
  restorationActivated: false,
  hasUnsavedChanges: false,
  cachedRestoration: {
    diff: {},
    identification_form_deletion: [],
    faq_items_deletion: [],
  },
});

export default produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_LIST_SUCCESS: {
      draft.campaigns = action.campaigns;
      break;
    }
    case entitiesActionTypes.FETCH_TAG_SETS_SUCCESS: {
      draft.tagSets = action.tagSets;
      break;
    }
    case actionTypes.CREATE_CAMPAIGN_SUCCESS: {
      const { name, publication_date, create_date, id, status } =
        action.campaignConfiguration;
      draft.campaigns = [
        ...draft.campaigns,
        // Add created campaign to the list to avoid querying the entire list again
        { name, publication_date, create_date, id, status },
      ];
      draft.customization[action.campaignId] = {
        currentCampaignConfiguration: action.campaignConfiguration,
        diff: {
          configuration_ui: {
            wording: {},
          },
          identification_form: [],
          faq_items: [],
        },
        identification_form_current_size:
          action.campaignConfiguration.identification_form?.length || 0,
        identification_form_deletion: [],
        faq_items_current_size: action.campaignConfiguration.faq_items.length,
        faq_items_deletion: [],
      };
      draft.hasUnsavedChanges = false;
      break;
    }
    case actionTypes.FETCH_CAMPAIGN_CONFIGURATION_REQUEST: {
      draft.previewActivated = false;
      draft.hasUnsavedChanges = false;
      draft.restorationActivated = false;
      break;
    }
    case actionTypes.FETCH_CAMPAIGN_CONFIGURATION_SUCCESS: {
      draft.customization[action.campaignId] = {
        currentCampaignConfiguration: action.campaignConfiguration,
        diff: {
          configuration_ui: {
            wording: {},
          },
          identification_form: [],
          faq_items: [],
        },
        identification_form_current_size:
          action.campaignConfiguration.identification_form?.length || 0,
        identification_form_deletion: [],
        faq_items_current_size: action.campaignConfiguration.faq_items.length,
        faq_items_deletion: [],
      };
      draft.hasUnsavedChanges = false;
      break;
    }
    case actionTypes.SAVE_CAMPAIGN_CONFIGURATION_SUCCESS: {
      draft.previewActivated = false;
      draft.hasUnsavedChanges = false;
      break;
    }
    case actionTypes.DELETE_CAMPAIGN_SUCCESS: {
      const indexToRemove = draft.campaigns
        .map((campaign) => campaign.id)
        .indexOf(action.campaignId);
      draft.campaigns.splice(indexToRemove, 1);
      draft.hasUnsavedChanges = false;
      break;
    }
    case actionTypes.CREATE_PREVIEW_CONFIG_SUCCESS: {
      draft.hasUnsavedChanges = false;
      break;
    }
    case actionTypes.ACTIVATE_RESTORE_DIFF: {
      draft.restorationActivated = true;
      draft.cachedRestoration = {
        diff: action.diff,
        identification_form_deletion: action.identification_form_deletion,
        faq_items_deletion: action.faq_items_deletion,
      };
      break;
    }
    case actionTypes.DEACTIVATE_RESTORE_DIFF: {
      draft.restorationActivated = false;
      draft.cachedRestoration = {
        diff: {},
        identification_form_deletion: [],
        faq_items_deletion: [],
      };
      break;
    }
    case actionTypes.RESTORE_DIFF: {
      draft.customization[action.campaignId] = {
        currentCampaignConfiguration:
          draft.customization[action.campaignId].currentCampaignConfiguration,
        diff: draft.cachedRestoration.diff,
        identification_form_deletion:
          draft.cachedRestoration.identification_form_deletion,
        identification_form_current_size: calculateCurrentSize(
          draft.customization[action.campaignId].currentCampaignConfiguration
            .identification_form,
          draft.cachedRestoration.diff.identification_form
        ),
        faq_items_current_size: calculateCurrentSize(
          draft.customization[action.campaignId].currentCampaignConfiguration
            .faq_items,
          draft.cachedRestoration.diff.faq_items
        ),
        faq_items_deletion: draft.cachedRestoration.faq_items_deletion,
      };
      draft.cachedRestoration = {
        diff: {},
        identification_form_deletion: [],
        faq_items_deletion: [],
      };
      draft.restorationActivated = false;
      draft.hasUnsavedChanges = false;
      break;
    }
    case actionTypes.ACTIVATE_PREVIEW: {
      draft.previewActivated = true;
      break;
    }
    case actionTypes.ADD_IDENTIFICATION_FORM_ELEMENT_SUCCESS: {
      draft.customization[action.campaignId].diff.identification_form.push(
        action.identification_form_element
      );
      draft.customization[
        action.campaignId
      ].identification_form_current_size += 1;
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.ADD_USER_FORM_ELEMENT_SUCCESS:
      draft.customization[action.campaignId].currentCampaignConfiguration[
        action.campaignField
      ].push(action.formElement);
      break;
    case actionTypes.UPDATE_USER_FORM_ELEMENT_SUCCESS:
      draft.customization[action.campaignId].currentCampaignConfiguration[
        action.campaignField
      ][
        draft.customization[action.campaignId].currentCampaignConfiguration[
          action.campaignField
        ].findIndex(({ id }) => id === action.element.id)
      ] = action.element;
      break;
    case actionTypes.DELETE_USER_FORM_ELEMENT_SUCCESS:
      draft.customization[action.campaignId].currentCampaignConfiguration[
        action.campaignField
      ] = [
        ...draft.customization[action.campaignId].currentCampaignConfiguration[
          action.campaignField
        ].filter(({ id }) => id !== action.formElementId),
      ];
      break;
    case actionTypes.RESET_ALL_FAQ_ITEMS_TO_DEFAULT: {
      draft.customization[action.campaignId].faq_items_deletion = [];
      draft.customization[action.campaignId].diff.faq_items = [];
      draft.customization[action.campaignId].faq_items_current_size =
        draft.customization[action.campaignId].currentCampaignConfiguration
          .faq_items?.length || 0;
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.RESET_WHOLE_IDENTIFICATION_FORM_TO_DEFAULT: {
      draft.customization[action.campaignId].identification_form_deletion = [];
      draft.customization[action.campaignId].diff.identification_form = [];
      draft.customization[action.campaignId].identification_form_current_size =
        draft.customization[action.campaignId].currentCampaignConfiguration
          .identification_form?.length || 0;
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.REMOVE_IDENTIFICATION_FORM_ELEMENT: {
      draft.customization[action.campaignId].identification_form_deletion.push(
        action.elementId
      );
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.RESTORE_IDENTIFICATION_FORM_ELEMENT: {
      draft.customization[
        action.campaignId
      ].identification_form_deletion.splice(action.index, 1);
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.UPDATE_IDENTIFICATION_FORM_ELEMENT: {
      const identificationForm =
        draft.customization[action.campaignId].diff.identification_form;
      let elementDiffIndex = identificationForm.findIndex(
        ({ id }) => id === action.element.id
      );
      let element = { ...action.element };
      if (elementDiffIndex === -1) {
        elementDiffIndex = identificationForm.length;
      } else {
        element = {
          ...identificationForm[elementDiffIndex],
          ...action.element,
        };
      }
      identificationForm[elementDiffIndex] = element;
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.DEACTIVATE_PREVIEW: {
      draft.previewActivated = false;
      break;
    }
    case actionTypes.SET_CONCEPT_RANDOMIZATION:
      draft.customization[action.campaignId].diff.randomize = action.randomize;
      break;
    case actionTypes.SET_CONCEPT_HIGHLIGHT:
      draft.customization[action.campaignId].diff.highlight_concepts =
        action.highlightConcepts.map((conceptId) => ({ id: conceptId }));
      break;
    case actionTypes.SET_EXPANDED_IDENTIFICATION_FORM:
      draft.customization[
        action.campaignId
      ].diff.configuration_ui.expanded_form = action.expanded_form;
      break;
    case actionTypes.SET_WORDING_VALUE: {
      if (
        !draft.customization[action.campaignId].diff.configuration_ui.wording[
          action.page
        ]
      ) {
        draft.customization[action.campaignId].diff.configuration_ui.wording[
          action.page
        ] = {};
      }
      draft.customization[action.campaignId].diff.configuration_ui.wording[
        action.page
      ][action.inputKey] = action.value;
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.SET_WORDING_TO_DEFAULT: {
      unset(
        draft.customization[action.campaignId].diff.configuration_ui.wording,
        [action.page, action.inputKey]
      );
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.ADD_FAQ_ITEM_SUCCESS: {
      draft.customization[action.campaignId].faq_items_current_size += 1;
      draft.customization[action.campaignId].diff.faq_items.push(
        action.faq_item
      );
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.REMOVE_FAQ_ITEM: {
      draft.customization[action.campaignId].faq_items_deletion.push(
        action.elementId
      );
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.RESTORE_FAQ_ITEM: {
      draft.customization[action.campaignId].faq_items_deletion.splice(
        action.index,
        1
      );
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.UPDATE_FAQ_ITEM: {
      if (action.alreadyInDiff) {
        draft.customization[action.campaignId].diff.faq_items[action.index] = {
          ...draft.customization[action.campaignId].diff.faq_items[
            action.index
          ],
          ...action.value,
        };
      } else {
        draft.customization[action.campaignId].diff.faq_items.push({
          position: action.position,
          id: action.elementId,
          ...action.value,
        });
      }
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.UPDATE_FAQ_ITEM_POSITION: {
      const indexInDIff = draft.customization[action.campaignId].diff.faq_items
        .map((item) => item.id)
        .indexOf(action.faqItem.id);
      if (indexInDIff === -1) {
        draft.customization[action.campaignId].diff.faq_items.push({
          // id: action.itemId,
          ...action.faqItem,
          position: action.newPosition,
        });
      } else {
        draft.customization[action.campaignId].diff.faq_items[
          indexInDIff
        ].position = action.newPosition;
      }
      draft.hasUnsavedChanges = true;
      break;
    }
    case actionTypes.UPDATE_IDENTIFICATION_FORM_ELEMENT_POSITION: {
      const indexInDIff = draft.customization[
        action.campaignId
      ].diff.identification_form
        .map((element) => element.id)
        .indexOf(action.element.id);
      if (indexInDIff === -1) {
        draft.customization[action.campaignId].diff.identification_form.push({
          ...action.element,
          position: action.newPosition,
        });
      } else {
        draft.customization[action.campaignId].diff.identification_form[
          indexInDIff
        ].position = action.newPosition;
      }
      draft.hasUnsavedChanges = true;
      break;
    }
    case userActionTypes.LOGOUT_SUCCESS:
    case userActionTypes.LOGIN_REQUEST:
    case userActionTypes.ADMIN_LOG_AS_REQUEST:
      return getCampaignInitialState();
    default:
      break;
  }
  return draft;
}, getCampaignInitialState());
