import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { api } from 'actions/utils';

import { StateStatus, createAPISlice } from 'utils/apiSlice';

import { fetchAggregates } from './monitorSlice';

const getFeedbackDataAsync = async (
  { campaignId, nextPage = false, nChunks = 10 },
  { getState }
) => {
  const {
    campaignSearch: { pagination, filters, period, customRangeDates },
  } = getState();

  const page = nextPage ? pagination.page + 1 : 1;
  const response = await api.post(
    `/feedback-review/${campaignId}/search/v1?page=${page}&page_size=${nChunks}`,
    { filters, period, customRangeDates }
  );
  return response.data;
};

// Thunks

export const fetchFeedback = createAsyncThunk(
  'campaignSearch/getFeedback:load',
  async ({ campaignId, nextPage = false }, { getState }) => {
    const data = await getFeedbackDataAsync(
      {
        campaignId,
        nextPage,
        nChunks: getState().campaignSearch?.pagination?.pageSize,
      },
      { getState }
    );
    return { ...data, resetFeedback: !nextPage };
  }
);

export const fetchExportFeedback =
  (campaignId) =>
  ({ nChunks }) =>
  async (_, getState) => {
    const data = await getFeedbackDataAsync(
      { campaignId, nChunks },
      { getState }
    );
    return data.feedbacks;
  };

export const annotateFeedback = createAsyncThunk(
  'campaignSearch/annotateFeedback:load',
  async ({ campaignId, feedbackId, formId, value }, { getState }) => {
    const { tag_set, form_type } = getState().campaign.customization[
      campaignId
    ].currentCampaignConfiguration.annotation_form.find(
      ({ id }) => formId === id
    );
    const payload = { form_element: { id: formId, form_type } };
    if (form_type === 'InputFormElement') {
      payload.value = value;
    } else if (form_type === 'MultipleChoiceFormElement') {
      payload.tag = { id: value };
      payload.tag_set = { id: tag_set.id };
    }
    const response = await api.post(
      `/feedback-review/${campaignId}/annotate/${feedbackId}`,
      payload
    );
    return response.data;
  }
);

const DEFAULT_FILTERS = {
  filters: {
    tags: {
      url_values: {},
      annotation_values: {},
      respondent_values: {},
      satisfaction_tag: [],
    },
  },
  shouldUpdateSearch: false,
  period: '1M',
  customRangeDates: {},
};

// Slice

const campaignSearchSlice = createAPISlice(
  {
    name: 'campaignSearch',
    initialState: {
      feedbacks: [],
      pagination: {
        page: 1,
        pageSize: 10,
        nPages: null,
      },
      ...DEFAULT_FILTERS,
      status: 'idle',
      displayFilters: false,
      focusFeedbackId: null,
    },
    reducers: {
      toggleDisplayFilters(state) {
        state.displayFilters = !state.displayFilters;
      },
      setFocusFeedbackId(state, { payload }) {
        state.focusFeedbackId = payload;
      },
      updateFilters(state, { payload }) {
        state.filters = { ...state.filters, ...payload };
        state.shouldUpdateSearch = true;
      },
      updateOneTagsFilter(
        state,
        { payload: { feedbackFormValuesFieldName, itemIds } }
      ) {
        state.filters.tags[feedbackFormValuesFieldName] = itemIds;

        state.shouldUpdateSearch = true;
      },
      updateTagsFilters(
        state,
        { payload: { feedbackFormValuesFieldName, tagSetId, itemIds } }
      ) {
        state.filters.tags[feedbackFormValuesFieldName] = {
          ...(state.filters.tags[feedbackFormValuesFieldName] || {}),
          [tagSetId]: itemIds,
        };
        state.shouldUpdateSearch = true;
      },
      resetFilters(
        state,
        { payload: { filterKey, isTag, feedbackFormValuesFieldName } }
      ) {
        if (filterKey || feedbackFormValuesFieldName) {
          // If filterName (payload) is provided, reset only this filter
          if (isTag) {
            if (filterKey) {
              state.filters.tags[feedbackFormValuesFieldName][filterKey] = [];
            } else {
              state.filters.tags[feedbackFormValuesFieldName] = [];
            }
          } else {
            state.filters[filterKey] = DEFAULT_FILTERS[filterKey];
          }
        } else {
          // Reset all filters otherwise
          state.filters = { ...DEFAULT_FILTERS.filters };
        }
        state.shouldUpdateSearch = true;
      },
      clearAllFilters(state) {
        state.filters = { ...DEFAULT_FILTERS.filters };
        state.period = DEFAULT_FILTERS.period;
        state.customRangeDates = DEFAULT_FILTERS.customRangeDates;
      },
      setPeriod(state, { payload }) {
        state.period = payload;
        state.customRangeDates = {};
        state.shouldUpdateSearch = true;
      },
      setCustomDate(state, { payload }) {
        state.customRangeDates = {
          ...state.customRangeDates,
          ...payload,
        };
        state.shouldUpdateSearch = true;
      },
      focusOnNextFeedback(state, { payload }) {
        const currentFeedbackIndex = state.feedbacks.findIndex(
          ({ id }) => id === state.focusFeedbackId
        );
        // If payload select next, select previous otherwise
        let nextIndex = null;
        if (payload) {
          nextIndex =
            currentFeedbackIndex + 1 <= state.feedbacks.length - 1
              ? currentFeedbackIndex + 1
              : 0;
        } else {
          nextIndex =
            currentFeedbackIndex - 1 >= 0
              ? currentFeedbackIndex - 1
              : state.feedbacks.length - 1;
        }
        state.focusFeedbackId = state.feedbacks[nextIndex].id;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        fetchFeedback.fulfilled,
        (state, { payload: { feedbacks, pagination, resetFeedback } }) => {
          state.feedbacks = resetFeedback
            ? feedbacks
            : [...state.feedbacks, ...feedbacks];
          // We keep the previous pagination values to allow computing
          // `total` and `n_pages` only once at first search (i.e. when `page == 1`)
          // while keeping the values accessible
          state.pagination.page = pagination.page;
          if (pagination.page === 1) {
            state.pagination = {
              ...state.pagination,
              total: pagination.total,
              nPages: pagination.n_pages,
              pageSize: pagination.page_size,
            };
          }
          state.shouldUpdateSearch = false;
        }
      );
      builder.addCase(annotateFeedback.fulfilled, (state, { payload }) => {
        state.feedbacks[
          state.feedbacks.findIndex(({ id }) => id === payload.id)
        ] = payload;
      });
    },
  },
  { keys: ['getFeedback', 'annotateFeedback'] }
);

export const {
  toggleDisplayFilters,
  setFocusFeedbackId,
  updateFilters,
  updateTagsFilters,
  updateOneTagsFilter,
  resetFilters,
  clearAllFilters,
  setPeriod,
  setCustomDate,
  focusOnNextFeedback,
} = campaignSearchSlice.actions;

export default campaignSearchSlice.reducer;

export const toggleFiltersPaneAndMaybeFetchFeedback = createAsyncThunk(
  'campaignSearch/getFeedbackMaybe:load',
  async (
    { campaignId, forceUpdate = false, lockPane = false },
    { getState, dispatch }
  ) => {
    const { displayFilters, shouldUpdateSearch } = getState().campaignSearch;
    if (shouldUpdateSearch || forceUpdate) {
      dispatch(fetchFeedback({ campaignId }));
      dispatch(fetchAggregates({ campaignId }));
    }
    if (displayFilters && !lockPane) {
      dispatch(toggleDisplayFilters());
    }
  }
);

// Selectors

export const feedbackSelector = (state) => state.campaignSearch.feedbacks;
export const feedbackByIdSelector = (selectedId) =>
  createSelector(feedbackSelector, (feedbacks) =>
    selectedId ? feedbacks.find(({ id }) => id === selectedId) : null
  );

export const loadingSelector = (state) =>
  state.campaignSearch.getFeedback === StateStatus.PENDING;
export const displayFiltersSelector = (state) =>
  state.campaignSearch.displayFilters;
export const focusFeedbackIdSelector = (state) =>
  state.campaignSearch.focusFeedbackId;
export const paginationSelector = (state) => state.campaignSearch.pagination;
export const filtersSelector = (state) => state.campaignSearch.filters;
export const oneFilterSelector = (filterKey) => (state) =>
  state.campaignSearch.filters?.[filterKey];
// export const channelsFilterSelector = oneFilterSelector('channels');

export const shouldUpdateSearchSelector = (state) =>
  state.campaignSearch.shouldUpdateSearch;

const isFiltersEmpty = (filters) => {
  if (!filters) {
    return true;
  }
  for (const [filterKey, filterValues] of Object.entries(filters)) {
    if (filterKey === 'tags') {
      for (const formFilterValue of Object.values(filterValues)) {
        if (!isFiltersEmpty(formFilterValue)) {
          return false;
        }
      }
    } else if (
      filterValues &&
      (filterValues.length > 0 || Object.values(filterValues)?.length > 0)
    ) {
      return false;
    }
  }
  return true;
};
export const emptyFiltersSelectors = createSelector(
  filtersSelector,
  isFiltersEmpty
);
export const periodFilterSelector = (state) => state.campaignSearch.period;
export const customPeriodDatesSelector = (state) =>
  state.campaignSearch.customRangeDates;

export const annotateLoadingSelector = (state) =>
  state.campaignSearch.annotateFeedback === StateStatus.PENDING;

export const tagFilterValuesSelector = ({ id, feedbackFormValuesFieldName }) =>
  createSelector(
    filtersSelector,
    (filters) => filters?.tags?.[feedbackFormValuesFieldName]?.[id] || []
  );

export const satisfactionTagFilterValuesSelector = () =>
  createSelector(
    filtersSelector,
    (filters) => filters?.tags?.satisfaction_tag || []
  );
