import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { api } from 'actions/utils';

import { StateStatus, createAPISlice } from 'utils/apiSlice';
import { processApiVisualizationData } from 'utils/facetAggregates';
import { objectMap } from 'utils/helpers';

export const fetchAggregates = createAsyncThunk(
  'campaignMonitor/fetchAggregates:load',
  async ({ campaignId }, { getState }) => {
    const { filters, period, customRangeDates } = getState().campaignSearch;
    const response = await api.post(
      `/feedback-review/${campaignId}/monitor/v1`,
      {
        filters,
        period,
        customRangeDates,
      }
    );
    return response.data;
  }
);

const campaignMonitorSlice = createAPISlice({
  name: 'campaignMonitor',
  initialState: {
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAggregates.fulfilled, (state, { payload }) => {
      state.data = {};
      Object.entries(payload).forEach(([aggregatesName, aggregatesData]) => {
        state.data[aggregatesName] =
          (aggregatesName === 'satisfaction_tag_distribution' &&
            processApiVisualizationData(aggregatesData)) ||
          ((aggregatesName === 'distributions' ||
            aggregatesName === 'ts' ||
            aggregatesName === 'tag_distributions') &&
            // Check existence of aggregatesData as `objectMap` cannot process a null value
            aggregatesData &&
            objectMap(aggregatesData, processApiVisualizationData)) ||
          aggregatesData;
      });
    });
  },
});

export default campaignMonitorSlice.reducer;

export const dataSelector = (state) => state.campaignMonitor.data;
export const loadingSelector = (state) =>
  state.campaignMonitor.state !== StateStatus.FULFILLED;
export const campaignMonitoringDataSelectorFactory = (accessor) =>
  createSelector(dataSelector, loadingSelector, (data, loading) =>
    loading ? null : accessor(data)
  );

export const campaignMonitoringHasNoFeedbackSelector = createSelector(
  campaignMonitoringDataSelectorFactory((data) => data?.kpi),
  (kpi) => kpi?.volume && !kpi?.volume?.value
);
