import { t } from '@lingui/macro';

import { createSelector } from '@reduxjs/toolkit';
import {
  productHierarchiesSelector,
  productHierarchyGroupsSelector,
  sourceGroupsSelector,
  sourcesSelector,
} from 'reducers/entityLabelFormatter';
import { viewFacetSelector } from 'selectors/view';

export const facetsSelector = (state) => state.facet.viewFacets;

export const viewFacetByIdSelector = (viewFacetId) =>
  createSelector(
    facetsSelector,
    (facets) => facets?.find((v) => v.id === viewFacetId) || null
  );

export const isComparativeViewFacet = (viewFacet) =>
  viewFacet.comparative_product_hierarchy_groups &&
  viewFacet.comparative_product_hierarchy_groups.length;

export const viewFacetSourceItemsSelector = createSelector(
  viewFacetSelector,
  sourceGroupsSelector,
  sourcesSelector,
  (viewFacet, sourceGroups, sources) =>
    sourceGroups[viewFacet.source_group.id].items?.map(({ id: sourceId }) => ({
      key: sourceId,
      value: sourceId,
      label: sources[sourceId].preferred_name,
    })) || []
);

export const viewFacetHierarchyGroupItemsSelector = createSelector(
  viewFacetSelector,
  productHierarchyGroupsSelector,
  (viewFacet, productHierarchyGroups) => {
    const groups = [];
    [
      viewFacet.base_product_hierarchy_group,
      ...(viewFacet.comparative_product_hierarchy_groups || []),
    ].forEach(({ id: hierarchyGroupId }) => {
      groups.push({
        key: hierarchyGroupId,
        value: hierarchyGroupId,
        label: productHierarchyGroups[hierarchyGroupId].name,
      });
    });
    return groups;
  }
);

const makeProductHierarchyItem = (item) => ({
  key: item.id,
  value: item.id,
  label: item.full_name,
  parent: item.parent,
});

export const viewFacetHierarchyItemsSelector = createSelector(
  viewFacetSelector,
  productHierarchiesSelector,
  productHierarchyGroupsSelector,
  (viewFacet, productHierarchies, productHierarchyGroups) => {
    const hierarchies = {};
    [
      viewFacet.base_product_hierarchy_group,
      ...(viewFacet.comparative_product_hierarchy_groups || []),
    ].forEach(({ id: hierarchyGroupId }) => {
      productHierarchyGroups[hierarchyGroupId].items.forEach((groupItem) => {
        const item = productHierarchies[groupItem.id];
        hierarchies[item.id] = makeProductHierarchyItem(item);
        if (
          item.parent &&
          !hierarchies[item.parent] &&
          productHierarchies[item.parent]
        ) {
          hierarchies[item.parent] = makeProductHierarchyItem(
            productHierarchies[item.parent]
          );
          // Add brand
          if (hierarchies[item.parent].parent) {
            hierarchies[hierarchies[item.parent].parent] =
              makeProductHierarchyItem(
                productHierarchies[hierarchies[item.parent].parent]
              );
          }
        }
      });
    });
    return Object.values(hierarchies);
  }
);

export const getTagSetItems = (tagSetId, tagSet) => ({
  ...tagSet,
  id: tagSetId,
  items:
    tagSet?.items.map(({ id, name }) => ({
      key: id,
      value: id,
      label: name,
    })) || [],
});

export const facetTagSetsSelector = (state) =>
  state.view.viewFacet.analyzed_tag_sets;

export const analysisTagSetsItemsSelector = createSelector(
  facetTagSetsSelector,
  (state) => state.entities.tagSets,
  (analyzedTagSets, tagSets) => {
    const analyzedTagSetIds = analyzedTagSets.map(({ id }) => id);
    return Object.entries(tagSets)
      .filter(([tagSetId]) => analyzedTagSetIds.includes(tagSetId))
      .map(([tagSetId, { name }]) => ({
        key: tagSetId,
        value: tagSetId,
        text: name,
      }));
  }
);

export const tagSetItemsSelector = (tagSetId, withEmptyValue = false) =>
  createSelector(
    (state) => state.entities.tagSets?.[tagSetId],
    (tagSet) => {
      const items = getTagSetItems(tagSetId, tagSet);
      if (withEmptyValue) {
        items.items.unshift({
          key: 'empty',
          value: '_empty_',
          label: `(${t`not-completed`})`,
        });
      }
      return items;
    }
  );
export const isFacetEmptySelector = (state) =>
  state.view.viewFacetAggregates[state.view.viewFacet?.id]?.isEmpty === true;
