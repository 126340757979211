import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
  appConfigSelector,
  isAuthenticatedSelector,
  isBwSelector,
} from 'selectors/user';

/**
 * Find best home path for a user based on their authentication status, authorizations and admin status.
 *
 * @param {Boolean} isAuthenticated - is the user authenticated
 * @param {Boolean} isAdmin - is the user an admin
 * @param {Boolean} isCsm - is the user a csm
 * @param {Boolean} analysis - is the analysis feature enabled
 * @param {Boolean} campaign - is the campaign feature enabled
 * @return {String} - the best home path for the user
 */
export const getHomePath = (isAuthenticated, isBw, analysis, campaign) => {
  if (!isAuthenticated) {
    return '/login';
  }
  if (isBw) {
    return '/admin';
  }

  if (analysis && campaign) {
    return window.location.pathname?.includes('campaign')
      ? '/campaign'
      : '/facets';
  }
  if (analysis) {
    return '/facets';
  }
  if (campaign) {
    return '/campaign';
  }
  return '/contact-support';
};

export function RedirectToHome() {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isBw = useSelector(isBwSelector);
  const { analysis, campaign } = useSelector(appConfigSelector);
  const homePath = getHomePath(isAuthenticated, isBw, analysis, campaign);
  return homePath ? <Navigate to={homePath} /> : null;
}

export function AdminOnlyRoute({ children }) {
  const isBw = useSelector(isBwSelector);
  if (!isBw) {
    // Home component will redirect to the best home path for the user
    return <RedirectToHome />;
  }
  return children;
}

AdminOnlyRoute.propTypes = { children: PropTypes.node.isRequired };
