import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'actions/utils';

import { StateStatus, createAPISlice } from 'utils/apiSlice';

export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels:load',
  async ({ campaignId }) => {
    const response = await api.get(`/campaign/${campaignId}/channel`);
    return { campaignId, channels: response.data };
  }
);

export const createChannel = createAsyncThunk(
  'channels/createChannel:load',
  async ({ campaignId, channelName }, { getState }) => {
    const payload = {
      campaign: { id: campaignId },
      url_values: [],
      name: channelName,
    };
    Object.entries(getState().campaignChannels.create).forEach(
      ([formFieldId, value]) => {
        if (value.tagSetId) {
          payload.url_values.push({
            form_element: {
              id: formFieldId,
              form_type: 'MultipleChoiceFormElement',
            },
            tag_set: { id: value.tagSetId },
            tag: { id: value.tagId },
          });
        } else {
          payload.url_values.push({
            form_element: { id: formFieldId, form_type: 'InputFormElement' },
            value,
          });
        }
      }
    );
    const response = await api.post(`/campaign/${campaignId}/channel`, payload);
    return { campaignId, channel: response.data };
  }
);

export const deleteChannel = createAsyncThunk(
  'channels/deleteChannel:load',
  async ({ campaignId, channelId }) => {
    await api.delete(`/campaign/${campaignId}/channel/${channelId}`);
    return { campaignId, channelId };
  }
);

const channelsSlice = createAPISlice(
  {
    name: 'channels',
    initialState: {
      create: {},
      channels: [],
      campaignIdChannels: {},
      focusChannelId: null,
    },
    reducers: {
      focusOnNextChannel(state, { payload: { campaignId, selectNext } }) {
        const channels = state.campaignIdChannels[campaignId] || [];
        const currentChannelIndex = channels.findIndex(
          ({ id }) => id === state.focusChannelId
        );
        // If payload select next, select previous otherwise
        const nextIndex =
          (selectNext &&
            (currentChannelIndex + 1 <= channels.length - 1
              ? currentChannelIndex + 1
              : 0)) ||
          (currentChannelIndex - 1 >= 0
            ? currentChannelIndex - 1
            : channels.length - 1);
        state.focusChannelId = channels[nextIndex].id;
      },
      setFocusChannelId(state, { payload }) {
        state.focusChannelId = payload;
      },
      setFormValue(state, { payload: { key, value } }) {
        state.create[key] = value;
      },
      resetCreateFormValues(state) {
        state.create = {};
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        fetchChannels.fulfilled,
        (state, { payload: { campaignId, channels } }) => {
          state.campaignIdChannels[campaignId] = channels;
        }
      );
      builder.addCase(
        createChannel.fulfilled,
        (state, { payload: { campaignId, channel } }) => {
          state.campaignIdChannels[campaignId] = [
            ...state.campaignIdChannels[campaignId],
            channel,
          ];
          state.create = {};
        }
      );
      builder.addCase(
        deleteChannel.fulfilled,
        (state, { payload: { campaignId, channelId } }) => {
          state.campaignIdChannels[campaignId] = state.campaignIdChannels[
            campaignId
          ].filter(({ id }) => id !== channelId);
        }
      );
    },
  },
  { keys: ['fetchChannels', 'createChannel', 'deleteChannel'] }
);

export const {
  focusOnNextChannel,
  setFocusChannelId,
  setFormValue,
  resetCreateFormValues,
} = channelsSlice.actions;

export default channelsSlice.reducer;

export const campaignChannelsSelector = (campaignId) => (state) =>
  state.campaignChannels.campaignIdChannels[campaignId];
export const focusChannelSelector = (campaignId) => (state) =>
  state.campaignChannels.focusChannelId
    ? state.campaignChannels.campaignIdChannels[campaignId]?.find(
        ({ id }) => id === state.campaignChannels.focusChannelId
      )
    : null;
export const loadingChannelsSelector = (state) =>
  state.campaignChannels.fetchChannels === StateStatus.PENDING;
export const createChannelFormValuesSelectorFactory = (state) =>
  state.campaignChannels.create;
